<template>
    <v-card :width="width - 300"
            :height="cardHeight"
            style="margin-top: 10px; overflow-x: auto; margin-left: -10px;"
            class="elevation-0">
        <v-card-text style="padding: 0; width: 100%;">
            <v-layout>
                <v-layout
                        style="width: 20%; min-width: 150px;"
                        v-for="item in tabs"
                        fill-height
                        :key="item.itemId">
                    <car-tab :title="item.title"
                             :item-id="item.itemId"
                             :height="cardHeight"
                             :watch-id="item.watchId"
                             :has-years="item.hasYears"
                             :prev-value-label="item.prevValueLabel"
                             :clear-filters="item.clearFilters"
                             :has-pagination="item.hasPagination"
                             :has-search="item.hasSearch"
                             :url="item.url"
                             :dialog="item.dialog"
                             :attribute="item.attribute"
                             @openDialog="openDialog($event)"/>
                </v-layout>
            </v-layout>
        </v-card-text>

        <v-dialog v-model="brandDialog"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :width="700">
            <brand-dialog @close="brandDialog = false"
                          @addBrand="addBrand($event)"
                          @showSnackbar="showSnackbar($event)"
                          :is-visible="brandDialog"/>
        </v-dialog>

        <v-dialog v-model="modelDialog"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :width="700">
            <model-dialog @close="modelDialog = false"
                          @addModel="addModel($event)"
                          @showSnackbar="showSnackbar($event)"
                          :is-visible="modelDialog"/>
        </v-dialog>

        <v-dialog v-model="generationDialog"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :width="700">
            <generation-dialog @close="generationDialog = false"
                               @addGeneration="addGeneration($event)"
                               @showSnackbar="showSnackbar($event)"
                               :is-visible="generationDialog"/>
        </v-dialog>

        <v-dialog v-model="serieDialog"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :width="700">
            <serie-dialog @close="serieDialog = false"
                          @addSerie="addSerie($event)"
                          @showSnackbar="showSnackbar($event)"
                          :is-visible="serieDialog"/>
        </v-dialog>

        <v-dialog v-model="trimDialog"
                  overlay-color="white"
                  overlay-opacity="0.5"
                  :width="700">
            <trim-dialog @close="trimDialog = false"
                         @addTrim="addTrim($event)"
                         @showSnackbar="showSnackbar($event)"
                         :is-visible="trimDialog"/>
        </v-dialog>

        <v-snackbar
                v-model="snackbar"
                color="secondary"
                top
                :timeout="1000"
        >
            {{ snackbarText }}
        </v-snackbar>
    </v-card>
</template>

<script>
    import CarTab from "./CarTab";
    import ModelDialog from "./ModelDialog";
    import BrandDialog from "./BrandDialog";
    import GenerationDialog from "./GenerationDialog";
    import SerieDialog from "./SerieDialog";
    import TrimDialog from "./TrimDialog";

    export default {
        name: "CarsList",
        components: {TrimDialog, SerieDialog, GenerationDialog, BrandDialog, ModelDialog, CarTab},
        data: () => ({
            tabs: [
                {
                    title: 'Марка',
                    itemId: 'idCarBrand',
                    attribute: 'brand',
                    url: 'brands',
                    clearFilters: ['idCarModel', 'idCarGeneration', 'idCarSerie', 'idCarTrim'],
                    hasPagination: true,
                    hasSearch: true,
                    dialog: 'brandDialog'
                },
                {
                    title: 'Модель',
                    itemId: 'idCarModel',
                    attribute: 'model',
                    url: 'models',
                    watchId: 'idCarBrand',
                    clearFilters: ['idCarGeneration', 'idCarSerie', 'idCarTrim'],
                    prevValueLabel: "Выберите марку",
                    hasPagination: true,
                    dialog: 'modelDialog'
                },
                {
                    title: 'Поколение',
                    itemId: 'idCarGeneration',
                    attribute: 'generation',
                    url: 'generations',
                    watchId: 'idCarModel',
                    hasYears: true,
                    clearFilters: ['idCarSerie', 'idCarTrim'],
                    prevValueLabel: "Выберите модель",
                    dialog: 'generationDialog'
                },
                {
                    title: 'Серия',
                    itemId: 'idCarSerie',
                    attribute: 'serie',
                    url: 'series',
                    watchId: 'idCarGeneration',
                    clearFilters: ['idCarTrim'],
                    prevValueLabel: "Выберите поколение",
                    dialog: 'serieDialog'
                },
                {
                    title: 'Модификация',
                    itemId: 'idCarTrim',
                    attribute: 'trim',
                    url: 'trims',
                    watchId: 'idCarSerie',
                    prevValueLabel: "Выберите серию",
                    dialog: 'trimDialog'
                }
            ],
            brandDialog: false,
            modelDialog: false,
            generationDialog: false,
            serieDialog: false,
            trimDialog: false,
            snackbar: false,
            snackbarText: ''
        }),
        computed: {
            width() {
                return this.$store.state.width
            },
            height() {
                return this.$store.state.height
            },
            isMobile() {
                return this.$store.state.isMobile
            },
            cardHeight() {
                return this.height - 100
            }
        },
        methods: {
            openDialog(dialog) {
                this[dialog] = true
            },
            showSnackbar(text) {
                this.snackbarText = text;
                this.snackbar = true
            },
            addBrand(brand) {
                this.$store.dispatch('setAddItem', brand[0]);
                const query = {
                    idCarBrand: brand[0].idCarBrand,
                    idCarModel: brand[0].model.idCarModel
                };
                console.log(query)
                this.$router.history.push({
                    name: 'cars',
                    query: query
                })
            },
            addModel(model) {
                this.$store.dispatch('setAddItem', model[0]);
                const query = {
                    idCarBrand: this.$route.query.idCarBrand,
                    idCarModel: model[0].idCarModel
                };
                console.log(query)
                this.$router.history.push({
                    name: 'cars',
                    query: query
                })
            },
            addGeneration(generation) {
                for (let gen of generation)
                    this.$store.dispatch('setAddItem', gen);
                const query = {
                    idCarBrand: this.$route.query.idCarBrand,
                    idCarModel: this.$route.query.idCarModel,
                    idCarGeneration: generation[0].idCarGeneration
                };
                console.log(query)
                this.$router.history.push({
                    name: 'cars',
                    query: query
                })
            },
            addSerie(serie) {
                for (let s of serie)
                    this.$store.dispatch('setAddItem', s);
                const query = {
                    idCarBrand: this.$route.query.idCarBrand,
                    idCarModel: this.$route.query.idCarModel,
                    idCarGeneration: this.$route.query.idCarGeneration,
                    idCarSerie: serie[0].idCarSerie
                };
                console.log(query)
                this.$router.history.push({
                    name: 'cars',
                    query: query
                })
            },
            addTrim(trim) {
                 for (let t of trim)
                    this.$store.dispatch('setAddItem', t);
                const query = {
                    idCarBrand: this.$route.query.idCarBrand,
                    idCarModel: this.$route.query.idCarModel,
                    idCarGeneration: this.$route.query.idCarGeneration,
                    idCarSerie: this.$route.query.idCarSerie,
                    idCarTrim: trim[0].idCarTrim
                };
                console.log(query)
                this.$router.history.push({
                    name: 'cars',
                    query: query
                })
            }
        }
    }
</script>

<style scoped>

</style>