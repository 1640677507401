<template>
    <v-form ref="form">
        <v-card style="padding: 25px 15px 20px 15px;" color="#F2F2F2">
            <v-card-title style="padding-top: 10px;">
                <v-layout>
                    <label class="display-1 font-weight-bold">Добавить модель</label>
                    <v-spacer/>
                    <v-btn icon large @click="$emit('close')">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-layout>
            </v-card-title>
            <v-card-text style="margin-top: 25px;">
                <v-layout align-center>
                    <label class="subtitle-1 label">Марка</label>
                    <label class="subtitle-1 font-weight-bold">{{currentCar.brand.title}}</label>
                </v-layout>

                <v-layout align-center style="margin-top: 40px;">
                    <label class="subtitle-1 label">Модель</label>
                    <v-text-field name="login"
                                  type="text"
                                  placeholder="Модель"
                                  :rules="carRules.modelTitleRules"
                                  color="secondary"
                                  style="max-width: 350px;"
                                  v-model.trim="model"
                                  @input="titleIsTaken = false"
                                  required/>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-layout style="padding-left: 140px;">
                    <v-btn style="width: 200px; border-radius: 8px; height: 43px; margin-bottom: 10px;"
                           color="secondary"
                           :loading="loading"
                           @click="saveData">
                        Добавить
                    </v-btn>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import DialogMixin from "../../mixins/DialogMixin";
    import ValidationMixin from "../../mixins/ValidationMixin";

    export default {
        name: "ModelDialog",
        mixins: [DialogMixin, ValidationMixin],
        data: () => ({
            model: '',
            error: false,
            loading: false
        }),
        methods: {
            clearData() {
                this.model = '';
                this.error = false;
            },
            saveData() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    const postData = {
                        title: this.model,
                        refId: this.$route.query.idCarBrand,
                        attribute: 'model'
                    };
                    const params = {
                        headers: {token: localStorage.getItem("token")}
                    };

                    this.$http.post("/cars/add", postData, params)
                        .then(response => {
                            this.$emit("addModel", response.data.data);
                            setTimeout(() => {
                                this.loading = false;
                                this.$emit("showSnackbar", "Модель авто добавлена");
                                this.$emit("close")
                            }, 300)
                        }).catch(error => {
                            console.log(error);
                            switch (error.response.status) {
                                case 401:
                                    this.$router.history.push({name: 'login'});
                                    break;
                                case 409:
                                    this.handleConflict();
                                    break;
                                default:
                                    alert('Ошибка сервера');
                                    break;
                            }
                            setTimeout(() => {
                                this.loading = false
                            }, 300)
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .label {
        width: 130px;
    }
</style>