<template>
    <v-layout class="content-layout" column>
        <label class="secondary--text display-1 font-weight-bold">Транспорт</label>
        <cars-list/>
    </v-layout>
</template>

<script>
    import CarsList from "../components/cars/CarsList";
    export default {
        name: "CarsView",
        components: {CarsList}
    }
</script>

<style scoped>

</style>