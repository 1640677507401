<template>
    <v-form ref="form">
        <v-card style="padding: 25px 15px 20px 15px;" color="#F2F2F2">
            <v-card-title style="padding-top: 10px;">
                <v-layout>
                    <label class="display-1 font-weight-bold">Добавить поколение</label>
                    <v-spacer/>
                    <v-btn icon large @click="$emit('close')">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-layout>
            </v-card-title>
            <v-card-text style="margin-top: 25px;">
                <v-layout align-center>
                    <label class="subtitle-1 label">Марка</label>
                    <label class="subtitle-1 font-weight-bold">{{currentCar.brand.title}}</label>
                </v-layout>

                <v-layout align-center style="margin-top: 20px;">
                    <label class="subtitle-1 label">Модель</label>
                    <label class="subtitle-1 font-weight-bold">{{currentCar.model.title}}</label>
                </v-layout>

                <v-layout align-center style="margin-top: 40px;">
                    <label class="subtitle-1 label">Поколение</label>
                    <v-text-field name="login"
                                  type="text"
                                  placeholder="Поколение"
                                  :rules="carRules.generationTitleRules"
                                  color="secondary"
                                  style="max-width: 350px;"
                                  @input="titleIsTaken = false"
                                  v-model.trim="model"
                                  required/>
                </v-layout>

                <v-layout align-start style="margin-top: 5px;">
                    <label class="subtitle-1 label" style="margin-top: 20px;">Годы выпуска</label>
                    <v-layout column>
                        <v-layout align-center>
                            <v-autocomplete :items="startYears"
                                            placeholder="Начало"
                                            color="secondary"
                                            :rules="carRules.yearRules"
                                            light
                                            style="max-width: 150px;"
                                            v-model="startYear"/>
                            <label class="subtitle-1" style="width: 50px; text-align: center;">-</label>
                            <v-autocomplete :items="endYears"
                                            :disabled="!startYear || isPresent"
                                            v-model="endYear"
                                            :rules="startYear && !isPresent ? carRules.yearRules : []"
                                            color="secondary"
                                            no-data-text="Список пуст"
                                            placeholder="Конец"
                                            style="max-width: 150px;"/>
                        </v-layout>
                        <v-layout style="margin-top: -20px;">
                            <v-checkbox label="Выпускается до сих пор"
                                        color="secondary"
                                        v-model="isPresent"/>
                        </v-layout>
                    </v-layout>
                </v-layout>
            </v-card-text>
            <v-card-actions>
                <v-layout style="padding-left: 140px; margin-top: -20px;">
                    <v-btn style="width: 200px; border-radius: 8px; height: 43px; margin-bottom: 10px;"
                           @click="saveData"
                           dark
                           color="secondary">
                        Добавить
                    </v-btn>
                </v-layout>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import DialogMixin from "../../mixins/DialogMixin";
    import ValidationMixin from "../../mixins/ValidationMixin";

    export default {
        name: "GenerationDialog",
        mixins: [DialogMixin, ValidationMixin],
        data: () => ({
            model: '',
            startYear: '',
            endYear: '',
            error: false,
            isPresent: false
        }),
        computed: {
            startYears() {
                let currentYear = new Date().getFullYear(), years = [];
                let startYear = 1885;
                while (startYear <= currentYear) {
                    years.push(startYear++);
                }
                return years;
            },
            endYears() {
                let currentYear = new Date().getFullYear(), years = [];
                let startYear = this.startYear + 1;
                while (startYear <= currentYear) {
                    years.push(startYear++);
                }
                return years;
            }
        },
        watch: {
            isPresent(to) {
                if (to) this.endYear = ''
            }
        },
        methods: {
            clearData() {
                this.model = '';
                this.error = false;
                this.startYear = '';
                this.endYear = '';
                this.isPresent = false
            },
            saveData() {
                if (this.$refs.form.validate()) {
                    this.loading = true;

                    const postData = {
                        title: this.model,
                        yearBegin: this.startYear,
                        yearEnd: this.endYear,
                        isPresent: this.isPresent,
                        refId: this.$route.query.idCarModel,
                        attribute: 'generation'
                    };
                    const params = {
                        headers: {token: localStorage.getItem("token")}
                    };

                    this.$http.post("/cars/add", postData, params)
                        .then(response => {
                            this.$emit("addGeneration", response.data.data);
                            setTimeout(() => {
                                this.loading = false;
                                this.$emit("showSnackbar", "Поколение авто добавлена");
                                this.$emit("close")
                            }, 300)
                        }).catch(error => {
                            console.log(error);
                            switch (error.response.status) {
                                case 401:
                                    this.$router.history.push({name: 'login'});
                                    break;
                                case 409:
                                    this.handleConflict();
                                    break;
                                default:
                                    alert('Ошибка сервера');
                                    break;
                            }
                            setTimeout(() => {
                                this.loading = false
                            }, 300)
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .label {
        width: 130px;
    }
</style>