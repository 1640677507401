<template>

</template>

<script>
    export default {
        name: "ValidationMixin",
        data: () => ({
            emailIsTaken: false,
            titleIsTaken: false,
            invalidPassword: false
        }),
        computed: {
            rules() {
                let isTaken = this.emailIsTaken;
                let invalidPassword = this.invalidPassword;
                // console.log('isTaken', isTaken)
                return {
                    nameRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length < 51 || 'Максимум 50 символов',
                    ],
                    emailRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => {
                            if (!!v) {
                                let r = /^[A-Z0-9a-z.%+\-_]+@[A-Za-z0-9]+\.[A-Za-z]{2,64}$/;
                                let match = v.match(r);
                                // console.log(match, v);
                                return !!v && match && match[0] === v.trim() || 'Неверный формат эл. адреса'
                            }
                            return 'Заполните поле'
                        },
                        v => !isTaken || 'Указанный логин занят'
                    ],
                    passwordRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length <= 30 && v.trim().length >= 6 || 'Длина пароля - от 6 до 30 символов',
                        v => !invalidPassword || 'Неправильный пароль'
                    ],
                    repeatPasswordRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        () => !this.repeatError || 'Пароли должны совпадать'
                    ]
                }
            },
            carRules() {
                let isTaken = this.titleIsTaken;

                return {
                    brandTitleRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length < 51 || 'Максимум 50 символов',
                        v => !isTaken || 'Марка с указанным названием уже есть в базе'
                    ],
                    brandModelTitleRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length < 51 || 'Максимум 50 символов'
                    ],
                    modelTitleRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length < 51 || 'Максимум 50 символов',
                        v => !isTaken || 'Модель с указанным названием уже есть в базе'
                    ],
                    generationTitleRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length < 101 || 'Максимум 100 символов',
                        v => !isTaken || 'Поколение с указанным названием уже есть в базе'
                    ],
                    serieTitleRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length < 101 || 'Максимум 100 символов',
                        v => !isTaken || 'Серия с указанным названием уже есть в базе'
                    ],
                    trimTitleRules: [
                        v => !!v && !!v.trim() || 'Заполните поле',
                        v => !!v && v.trim().length < 101 || 'Максимум 100 символов',
                        v => !isTaken || 'Модификация с указанным названием уже есть в базе'
                    ],
                    yearRules: [
                        v => !!v || 'Заполните поле'
                    ],
                }

            }
        },
        methods: {
            handleConflict() {
                this.titleIsTaken = true;
                console.log(this.titleIsTaken);
                this.validateForm()
            },
            validateForm() {
                this.$nextTick().then(() => {
                    this.$refs.form.validate()
                })
            }
        }
    }
</script>
