<script>
    export default {
        name: "DialogMixin",
        props: {
            isVisible: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            currentCar() {
                return this.$store.state.currentCar
            }
        },
        watch: {
            isVisible(val) {
                if (this.$refs.form) this.$refs.form.resetValidation();
                if (!val) this.clearData()
            }
        }
    }
</script>