<template>
    <v-card style="margin: 0; width: 100%; padding-left: 10px;"
            class="elevation-1"
            :height="height">
        <v-card-title style="padding: 10px 0 0 0; ">
            <v-layout column>
                <label class="subtitle-1">{{title}}</label>
                <template v-if="hasSearch">
                    <v-autocomplete hide-details
                                    :items="searchedItems"
                                    hide-no-data
                                    light
                                    v-model="selected"
                                    item-text="title"
                                    dense
                                    color="secondary"
                                    :item-value="itemId"
                                    return-object
                                    hide-selected
                                    solo
                                    style="margin-left: -5px; width: 100%; margin-top: 10px;"
                                    prepend-inner-icon="mdi-magnify"
                                    @click:clear="clearSelection"
                                    :search-input.sync="search"
                                    clearable/>
                </template>
                <div class="subtitle-2 font-weight-bold text-left"
                     style="margin-top: 15px; margin-bottom: 0;"
                     v-if="selectedId || !watchId">
                    <label class="pointer light-hover" @click="$emit('openDialog', dialog)">Добавить</label>
                </div>
            </v-layout>
        </v-card-title>
        <v-card-text class="scrollable-card scroll"
                     style="margin-top: 5px;"
                     :style="{'max-height': height - (hasSearch ? 130 : 80) + 'px'}"
                     @scroll="handleScroll">
            <template v-if="items.length">
                <v-list style="width: 100%; height :100%; margin-top: 5px; margin-left: -5px;" dense>
                    <v-overlay absolute v-model="loading">
                        <v-progress-circular color="white" indeterminate/>
                    </v-overlay>
                    <v-list-item v-for="item in items"
                                 :key="item[itemId]"
                                 style="padding-left: 5px;"
                                 :class="item[itemId] == $route.query[itemId] ? 'font-weight-black' : ''"
                                 @click.prevent="push(item[itemId], item.title, item.yearBegin, item.yearEnd)">
                        <v-list-item-content class="text-truncate" style="padding-left: 0;">
                            <template v-if="hasYears">
                                <v-layout column>
                                    <label>{{item.title}}</label>
                                    <template v-if="item.yearBegin && item.yearEnd">
                                        <template v-if="item.yearBegin === item.yearEnd">
                                            <p style="margin-top: 5px; margin-bottom: 5px;">
                                                <b>{{item.yearBegin}}</b>
                                            </p>
                                        </template>
                                        <template v-else>
                                            <p style="margin-top: 5px; margin-bottom: 5px;">
                                                <b>{{item.yearBegin}}-{{item.yearEnd}}</b>
                                            </p>
                                        </template>
                                    </template>
                                    <template v-else-if="item.yearBegin">
                                        <p style="margin-top: 5px; margin-bottom: 5px;">
                                            <b>{{item.yearBegin}} - н.в.</b>
                                        </p>
                                    </template>
                                </v-layout>
                            </template>

                            <template v-else>
                                {{item.title}}
                            </template>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <template v-else-if="selectedId">
                <div style="padding-top: 10px; ">
                    <label class="grey--text">Результатов не найдено.</label>
                </div>
            </template>
            <template v-else>
                <div style="padding-top: 15px; ">
                    <label class="grey--text">{{prevValueLabel}}</label>
                </div>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "CarTab",
        props: {
            title: {
                type: String,
                required: true
            },
            itemId: {
                type: String,
                required: true
            },
            attribute: {
                type: String,
                required: true
            },
            dialog: {
                type: String,
                required: true
            },
            watchId: {
                type: String
            },
            url: {
                type: String,
                required: true
            },
            height: {
                type: Number,
                required: true
            },
            clearFilters: {
                type: Array
            },
            hasYears: {
                type: Boolean
            },
            hasPagination: {
                type: Boolean
            },
            hasSearch: {
                type: Boolean
            },
            prevValueLabel: {
                type: String
            }
        },
        data: () => ({
            items: [],
            loading: false,
            perPage: 30,
            page: 0,
            searchLimit: 1000,
            searchedItems: [],
            searchLoading: false,
            search: null,
            searchText: 'Результатов не найдено',
            selected: null
        }),
        computed: {
            selectedId() {
                return this.$route.query[this.watchId]
            },
            addItem() {
                return this.$store.state.addItem
            }
        },
        watch: {
            '$route'(to, from) {
                if (to.query[this.watchId] && to.query[this.watchId] !== from.query[this.watchId]) {
                    this.page = 0;
                    this.getItems(true);
                } else if (this.watchId && !to.query[this.watchId])
                    this.items = []
            },
            search(val) {
                let params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        page: 0,
                        limit: this.searchLimit,
                        searchString: val
                    }
                };

                // console.log('params', params);
                this.$http.get("/cars/" + this.url, params)
                    .then(response => {
                        // console.log(response.data.data)
                        this.searchedItems = response.data.data
                    }).catch(error => {
                    console.log(error);
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            // console.log('error');
                            break;
                    }
                })
            },
            selected(to) {
                if (to && to[this.itemId]) {
                    const title = to.title;
                    const itemId = this.itemId;
                    this.items = [{
                        [itemId]: to[itemId],
                        title: title
                    }]
                }
            },
            addItem(val) {
                if (val && val[this.itemId]) {
                    if (this.items.length < this.perPage || this.items.slice(-1)[0].title > val.title) {
                        this.items.push(val);
                        this.items.sort((v, s) => v.title > s.title);
                    }

                    if (val.idCarBrand) {
                        this.$store.dispatch('setCurrentCar',
                            {
                                key: 'brand',
                                data: {
                                    idCarBrand: val.idCarBrand,
                                    title: val.title
                                }
                            })
                    }
                    if (val.model) {
                        this.$store.dispatch('setCurrentCar',
                            {
                                key: 'model',
                                data: {
                                    idCarModel: val.idCarModel,
                                    title: val.title
                                }
                            })
                    }
                    if (val.idCarModel) {
                        this.$store.dispatch('setCurrentCar',
                            {
                                key: 'model',
                                data: {
                                    idCarModel: val.idCarModel,
                                    title: val.title
                                }
                            })
                    }
                    if (val.idCarGeneration) {
                        this.$store.dispatch('setCurrentCar',
                            {
                                key: 'model',
                                data: {
                                    idCarModel: val.idCarGeneration,
                                    title: val.title
                                }
                            })
                    }
                    if (val.idCarSerie) {
                        this.$store.dispatch('setCurrentCar',
                            {
                                key: 'model',
                                data: {
                                    idCarModel: val.idCarSerie,
                                    title: val.title
                                }
                            })
                    }
                    this.$store.dispatch('setAddItem', null)
                }
            }
        },
        methods: {
            getItems(isRewrite, newId) {
                if (this.selectedId || !this.watchId) {
                    this.loading = true;

                    let params = {
                        headers: {token: localStorage.getItem('token')},
                        params: {
                            page: isRewrite ? 0 : this.page,
                            limit: this.perPage
                        }
                    };
                    if (this.watchId && !newId)
                        params.params[this.watchId] = this.selectedId;

                    //console.log('params', params);
                    this.$http.get("/cars/" + this.url, params)
                        .then(response => {
                            if (isRewrite) {
                                this.page = 1;
                                this.items = response.data.data;
                            } else {
                                if (response.data.data.length) {
                                    this.page += 1;
                                    this.items.push(...response.data.data);
                                }
                            }
                            //(this.itemId, 'here')
                        }).catch(error => {
                        console.log(error);
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    }).finally(() => {
                        setTimeout(() => {
                            this.loading = false
                        }, 500)
                    })
                }
            },
            handleScroll(event) {
                if (this.hasPagination && event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight)
                    this.getItems()
            },
            push(id, title, yearStart, yearEnd) {
                let itemId = this.itemId;
                let attribut = this.attribute;
                let query = this.clearedFilters(id);
                this.$store.dispatch('setCurrentCar',
                    {
                        key: attribut,
                        data: {
                            [itemId]: id,
                            title: title,
                            yearBegin: yearStart,
                            yearEnd: yearEnd
                        }
                    })
                this.$router.history.push({name: 'cars', query: query})
            },
            clearedFilters(id) {
                let query = {...this.$route.query};
                let newQuery = {};
                for (let i in query) {
                    if (!this.clearFilters || !this.clearFilters.includes(i))
                        newQuery[i] = query[i]
                }
                if (id)
                    newQuery[this.itemId] = id;
                else
                    delete newQuery[this.itemId];
                return newQuery
            },
            clearSelection() {
                let query = this.clearedFilters();
                this.$router.history.push({name: 'cars', query: query})
                this.getItems(true)
            }
        },
        beforeMount() {
            this.getItems()
        }
    }
</script>

<style scoped>
    .active {
        background-color: #ddd;
    }

    .info-label {
        margin-top: 40px;
    }

    .scrollable-card {
        padding: 0;
        overflow-y: auto;
    }

    .scroll {
        scrollbar-color: grey lightgrey;
        scrollbar-width: thin;
    }

    .light-hover:hover {
        color: grey
    }
</style>